const GET_OAUTH_URLS_ING = 'OAUTH/GET_OAUTH_URLS_ING'
const GET_OAUTH_URLS_SUCCESS = 'OAUTH/GET_OAUTH_URLS_SUCCESS'
const GET_OAUTH_URLS_FAILURE = 'OAUTH/GET_OAUTH_URLS_FAILURE'

const SIGNIN_BY_OAUTH_ING = 'OAUTH/SIGNIN_BY_OAUTH_ING'
const SIGNIN_BY_OAUTH_SUCCESS = 'OAUTH/SIGNIN_BY_OAUTH_SUCCESS'
const SIGNIN_BY_OAUTH_FAILURE = 'OAUTH/SIGNIN_BY_OAUTH_FAILURE'

const defaultState = {
  urls: []
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case GET_OAUTH_URLS_SUCCESS: 
      return {
        ...state,
        urls: action.payload
      }
    default:
      return {...state}
  }
}

export const getUrls = () => {
  return {
    types: [GET_OAUTH_URLS_ING, GET_OAUTH_URLS_SUCCESS, GET_OAUTH_URLS_FAILURE],
    promise: (apiClient) => apiClient.get('/api/oauth/urls')
  }
}

export const signinByOAuth = ({type, code, state, invitationCode}) => {
  return {
    types: [SIGNIN_BY_OAUTH_ING, SIGNIN_BY_OAUTH_SUCCESS, SIGNIN_BY_OAUTH_FAILURE],
    promise: (apiClient) => apiClient.post('/api/oauth/signin', {
      type,
      code,
      state,
      invitationCode
    })
  }
}