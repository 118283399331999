import React from 'react'
import { connect } from 'react-redux'
import { Form, Button, message } from 'antd'
import { signinByOAuth } from '../../redux/oauth'
import { reportOAuthExp, reportOAuthSuccess } from '../../utils/report'

class OAuth extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      email: '',
      password: ''
    }
    const url = new URL(window.location.href)
    const type = url.searchParams.get('type')
    const code = url.searchParams.get('code')
    const state = url.searchParams.get('state')
    const invitationCode = localStorage.getItem('INVITATION_CODE') || ''
    this.props.dispatch(signinByOAuth({type, code, state, invitationCode}))
      .then(response => {
        if (response.errorMsg) {
          return message.error(response.errorMsg, 3, () => {
            this.props.history.push('/')
          })
        }
        if (invitationCode) {
          localStorage.removeItem('INVITATION_CODE')
        }
        reportOAuthSuccess()
        if (response.payload && response.payload.isNewUser) {
          this.props.history.push('/guide/createcertificate')
        } else {
          this.props.history.push('/monitor/dashboard')
        }
      })
  }

  componentDidMount() {
    reportOAuthExp()
  }

  handlerClickTitle = () => {
    this.props.history.push('/')
  }

  render() {
    return (
      <div className="area oauth-signin">
        <ul className="circles">
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
        <div className='form-container'>
          <Form>
            <div className='title' onClick={this.handlerClickTitle}>
              <h1>OHTTPS</h1>
              <h4>免费HTTPS证书&nbsp;•&nbsp;自动化更新&nbsp;•&nbsp;自动化部署&nbsp;•&nbsp;自动化监控</h4>
            </div>
            <Button type='primary' loading block>第三方授权登录中，成功后将自动跳转</Button>
          </Form>
        </div>
      </div >
    )
  }
}

OAuth = connect()(OAuth)

export default OAuth
