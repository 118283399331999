import React from 'react'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import PropTypes from 'prop-types'
import moment from 'moment'
import {Table, Button, Tooltip, Modal} from 'antd'
import {getCertificatePushes} from '../redux/certificate/push'
import {CERTIFICATE_PUSH_STATUS, CLOUD_ID, CLOUD_PUSH_POSITION} from '../utils/constant'
import SSHPushDetail from './SSHPushDetail'

class CertificatePushes extends React.Component {
  static props = {
    certificateId: PropTypes.number,
    initRef: PropTypes.func
  }

  constructor (props) {
    super(props)
    this.state = {
      current: 1,
      offset: 0,
      limit: 10,
      certificatePushDetailModalVisible: false,
    }
    this.searchCertificatePushes()
    if (props.initRef) {
      props.initRef(this)
    }
  }

  searchCertificatePushes = (state) => {
    const {offset, limit} = Object.assign({}, this.state, state)
    const {certificateId} = this.props
    this.props.dispatch(getCertificatePushes({certificateId, offset, limit})).then((response) => {
      if (response.payload && response.payload.rows.length > 0) {
        if (response.payload.rows.some(item => item.status === CERTIFICATE_PUSH_STATUS.PUSHING)) {
          if (this.timer || this.isUnmounted) {
            return
          }
          this.timer = setInterval(() => {
            this.searchCertificatePushes()
          }, 3 * 1000)
        } else {
          if (this.timer) {
            clearInterval(this.timer)
            this.timer = null
          }
        }
      }
    })
  }

  componentWillUnmount () {
    this.isUnmounted = true
    if (this.timer) {
      clearInterval(this.timer)
      this.timer = null
    }
  }

  handelCloseCertificatePushDetailModal = () => {
    this.setState({
      certificatePushDetailModalVisible: false
    })
  }

  handelOpenCertificatePushDetailModal = (certificatePushId) => {
    this.setState({
      certificatePushId,
      certificatePushDetailModalVisible: true
    })
  }

  get tableColumns () {
    return [{
      title: '部署时间',
      dataIndex: 'createTime',
      key: 'createTime',
      width: 142,
      render: createTime => moment(createTime).format('YYYY-MM-DD HH:mm')
    }, {
      title: '任务创建',
      dataIndex: 'isAuto',
      key: 'isAuto',
      render: (text, record) => record.isAuto ? '系统自动创建' : '用户手动创建',
      width: 98
    }, {
      title: '部署节点',
      dataIndex: 'cloudServerId',
      key: 'cloudServerId',
      render: (text, record) => {
        const cloud = `${record.cloudProviderName} - ${record.cloudPushPosition} - ${record.cloudServerAlias || record.cloudServerName}`
        return (
          <Tooltip title={cloud}>
            <Button onClick={() => this.props.history.push(`/monitor/cloudservers/${record.cloudServerId}`)} type='link' style={{paddingLeft: 0, height: 18}}>
              <img src={record.cloudProviderLogo} alt='' width={15} height={15}/>&nbsp;
                <span style={{overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '400px', verticalAlign: 'middle', textWrap: 'nowrap'}}>
                  {cloud}
                </span>
            </Button>
          </Tooltip>
        )
      }
    }, {
      title: '状态',
      dataIndex: 'status',
      key: 'status',
      width: 62,
      render: (text, record) => {
        if (record.status === CERTIFICATE_PUSH_STATUS.PUSHING) {
          return <span style={{color: 'orange'}}>部署中</span>
        } else if (record.status === CERTIFICATE_PUSH_STATUS.PUSHED) {
          return <span style={{color: 'green'}}>已成功</span>
        } else if (record.status === CERTIFICATE_PUSH_STATUS.ERROR) {
          return <span style={{color: 'red'}}>已失败</span>
        }
      }
    }, {
      title: '失败信息',
      dataIndex: 'error',
      key: 'error',
      render: error => {
        error = error || '-'
        return (
          <Tooltip title={error}>
            <div style={{overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '500px', verticalAlign: 'middle', textWrap: 'nowrap'}}>
              {error}
            </div>
          </Tooltip>
        )
      }
    }, {
      title: '备注',
      dataIndex: 'extra',
      key: 'extra',
      ellipsis: true,
      render: (text, record) => {
        if (record.status === CERTIFICATE_PUSH_STATUS.PUSHING) {
          return '-'
        }

        if (record.cloudPushPosition === CLOUD_PUSH_POSITION.SSH) {
          return <Button type='link' style={{padding: '0px'}} onClick={this.handelOpenCertificatePushDetailModal.bind(this, record.id)} >点击查看执行详情</Button>
        }

        if (!record.extra) {
          return '-'
        }

        switch(record.cloudId) {
          case CLOUD_ID.DOCKER:
          case CLOUD_ID.SYNO:
            const extraInJSON = JSON.parse(record.extra)
            if (extraInJSON.failureNodesCount) {
              if (extraInJSON.successNodesCount) {
                return <span>共{extraInJSON.containerNodesCount}个运行中的容器，<span style={{color: 'green'}}>部署成功{extraInJSON.successNodesCount}个</span>，<span style={{color: 'red'}}>更新失败{extraInJSON.failureNodesCount}个</span></span>
              } else {
                return <span>共{extraInJSON.containerNodesCount}个运行中的容器，<span style={{color: 'red'}}>部署失败{extraInJSON.failureNodesCount}个</span></span>
              }
            } else {
              return <span>共{extraInJSON.containerNodesCount}个运行中的容器，<span style={{color: 'green'}}>部署成功{extraInJSON.successNodesCount}个</span></span>
            }
          default:
            return record.extra
        }
      }
    }, {
      title: '', // 此列用于修复超长字段影响表格布局的bug, https://github.com/ant-design/ant-design/issues/13825#issuecomment-449889241
      fixed: 'right',
      width: 1
    }]
  }

  get tableData () {
    return [...(new Array(this.state.offset)), ...this.props.pushes.rows.map((item, index) => {
      item.key = index + this.state.offset + 1
      return item
    })]
  }

  handleChangePage = (page, pageSize) => {
    const offset = pageSize * (page - 1)
    const limit = pageSize
    this.setState({
      current: page,
      offset,
      limit
    })
    this.searchCertificatePushes({
      offset,
      limit
    })
  }

  handleChangePageSize = (current, size) => {
    const offset = 0
    const limit = size
    this.setState({
      current: 1,
      offset,
      limit
    })
    this.searchCertificatePushes({
      offset,
      limit
    })
  }

  handelCloseCertificatePushDetailModal = () => {
    this.setState({
      certificatePushDetailModalVisible: false
    })
  }

  handelOpenCertificatePushDetailModal = (certificatePushId) => {
    this.setState({
      certificatePushId,
      certificatePushDetailModalVisible: true
    })
  }

  renderCertificatePushDetailModal = () => {
    const {certificatePushId, certificatePushDetailModalVisible} = this.state
    if (!certificatePushDetailModalVisible) {
      return
    }

    const {certificateId} = this.props
    return (
      <Modal
        title={'证书部署执行详情'}
        visible={true}
        width={800}
        okType='primary'
        okButtonProps={{ style: { marginRight: '5px' } }}
        onOk={this.handelCloseCertificatePushDetailModal}
        onCancel={this.handelCloseCertificatePushDetailModal}
        footer={[
          <Button type='primary' size='small' key='confirm' onClick={this.handelCloseCertificatePushDetailModal}>
            关闭
          </Button>
        ]}
      >
        <div>
          <SSHPushDetail 
            certificateId={certificateId}
            certificatePushId={certificatePushId}
          />
        </div>
      </Modal>
    )
  }

  render () {
    return (
      <>
        <Table
          columns={this.tableColumns}
          dataSource={this.tableData}
          size='small'
          scroll={{x: true}}
          pagination={{
            current: this.state.current,
            pageSize: this.state.limit,
            total: this.props.pushes.count,
            pageSizeOptions: ['10', '20', '50', '100'],
            showSizeChanger: true,
            showTotal: total => `共${total}条`,
            onChange: this.handleChangePage,
            onShowSizeChange: this.handleChangePageSize
          }}
        />
        {this.renderCertificatePushDetailModal()}
      </>
    )
  }
}

CertificatePushes = connect((state, props) => {
  const certificateId = props.certificateId
  return {
    pushes: state.certificate.push[`pushes_${certificateId}`] || {count: 0, rows: []}
  }
})(CertificatePushes)

CertificatePushes = withRouter(CertificatePushes)

export default CertificatePushes
