export const ERRORS = {
  /** 登录已失效，请重新登录 */
  CM0004: 'CM0004',
  /** 账户余额不足 */
  CM0008: 'CM0008',
}

export const CERTIFICATE_TYPE = {
  SINGLE: 'SINGLE',
  MULTI: 'MULTI',
  WILDCARD: 'WILDCARD',
  IP: 'IP'
}

export const CERTIFICATE_BRAND = {
  LETSENCRYPT: 'LETSENCRYPT',
  SECTIGO: 'SECTIGO'
}

export const CERTIFICATE_VERSION_STATUS = {
  QUEUING: 'QUEUING',
  GENERATING: 'GENERATING',
  GENERATED: 'GENERATED',
  REVOKED: 'REVOKED',
  ERROR: 'ERROR'
}

export const CERTIFICATE_PUSH_STATUS = {
  PUSHING: 'PUSHING',
  PUSHED: 'PUSHED',
  ERROR: 'ERROR'
}

export const EMAIL_TEMPLATE = {
  VERIFICATION_CODE: 1,
  RESET_PASSWORD: 2
}

export const CONFIG_FIELD_TYPE = {
  INPUT: 'INPUT',
  PASSWORD: 'PASSWORD',
  SELECT: 'SELECT',
  RANDOM: 'RANDOM',
  CODE: 'CODE'
}

export const CLOUD_ID = {
  ALIYUN: 1,
  TCLOUD: 2,
  DOCKER: 3,
  QINIU: 4,
  BTCN: 5,
  SYNO: 13,
}

export const CERTIFICATE_AUTH_MODE = {
  DNS_API_AUTH_MODE: 'DNS_API_AUTH_MODE',
  DNS_ALIAS_AUTH_MODE: 'DNS_ALIAS_AUTH_MODE',
  HTTP_AUTH_MODE: 'HTTP_AUTH_MODE',
  HTTP_MAN_AUTH_MODE: 'HTTP_MAN_AUTH_MODE'
}

export const CERTIFICATE_WHEN = {
  APPLY: 'APPLY',
  MAN_UPDATE: 'MAN_UPDATE',
  AUTO_UPDATE: 'AUTO_UPDATE'
}

export const CERTIFICATE_AUTH_MODE_DISPLAY_NAME = {
  DNS_API_AUTH_MODE: 'DNS授权验证',
  DNS_ALIAS_AUTH_MODE: '免DNS授权验证',
  HTTP_AUTH_MODE: 'HTTP代理验证',
  HTTP_MAN_AUTH_MODE: 'HTTP文件验证'
}

export const WATCH_STATUS = {
  CREATED: 'CREATED',
  DELETED: 'DELETED',
  PAUSED: 'PAUSED'
}

export const WATCH_RECORD_STATUS = {
  WATCHING: 'WATCHING',
  WATCHED: 'WATCHED',
  ERROR: 'ERROR'
}

export const OCSP_STATUS = {
  GOOD: 'GOOD',
  REVOKED: 'REVOKED',
  UNKNOWN: 'UNKNOWN'
}

export const OAUTH_TYPE = {
  ALIYUN: 'ALIYUN',
  GITHUB: 'GITHUB',
  GITLAB: 'GITLAB',
  GITEE: 'GITEE',
  GOOGLE: 'GOOGLE'
}

export const SYSTEM_EMAIL_NOTIFICATION_TARGET = {
  ID: 0,
  NAME: '系统推送',
  NOTIFICATION_PROVIDER_NAME: '邮件'
}

export const PAYMENT_PROVIDER = {
  PAYJS: 'PAYJS',
  STRIPE: 'STRIPE'
}

export const PAYMENT_TYPE = {
  ALIPAY: 'alipay',
  WECHAT_PAY: 'wechat_pay',
  OTHER: 'other'
}

export const ACME_CHALLENGE_TYPE = {
  HTTP_01: 'http-01',
  DNS_01: 'dns-01'
}

export const CLOUD_PUSH_POSITION = {
  SSH: 'SSH'
}
